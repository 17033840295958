import exceljs from 'exceljs';

const FORMAT = 'dd.mm.yyyy';

export async function createJsonFromExcel(file) {
  const cellValueToDict2 = (keys, row) => {
    const data = {};
    row.eachCell((cell, colNumber) => {
      let { value } = cell;
      // eslint-disable-next-line no-prototype-builtins
      if (typeof value === 'object' && value.hasOwnProperty('text')) {
        value = value.text;
      }
      data[colNumber] = value;
    });
    return data;
  };
  const changeRowsToDict = (worksheet) => {
    const dataArray = [];
    let keys = [];
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber === 1) {
        keys = [...Array(row.values.length).keys()];
      } else {
        const rowDict = cellValueToDict2(keys, row);
        dataArray.push(rowDict);
      }
    });
    return dataArray;
  };
  const cleanExcelData = (data) => {
    if (data instanceof Date) {
      const day = String(data.getDate()).padStart(2, '0');
      const month = String(data.getMonth() + 1).padStart(2, '0');
      const year = data.getFullYear();
      return FORMAT.replace('dd', day).replace('mm', month).replace('yyyy', year);
    }
    if (Array.isArray(data)) {
      return data.map((item) => cleanExcelData(item));
    }
    if (typeof data === 'object' && data !== null) {
      if (data.richText) {
        return data.richText.map((rt) => rt.text).join('');
      }
      const result = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          result[key] = cleanExcelData(data[key]);
        }
      }
      return result;
    }
    return data;
  };
  return new Promise((resolve, reject) => {
    const workbook = new exceljs.Workbook();
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const buffer = e.target.result;
      workbook.xlsx.load(buffer)
        .then(async (wb) => {
          const worksheet = wb.getWorksheet(1);
          const dataArray = changeRowsToDict(worksheet);
          console.log(dataArray);
          const clearedArray = cleanExcelData(dataArray);
          console.log(clearedArray);
          resolve(clearedArray);
        })
        .catch((error) => {
          console.log(error);
        });
    };
  });
}
