<template>
  <div class="no-contract-notice" v-if="!currentContractId">Необходимо выбрать договор!</div>
  <div class="employees-lists" v-else>
    <a
      href="https://medsafe.pro/shablon_employee.xlsx"
      class="employees-lists__template-link">
      <mcw-button>
        <mcw-material-icon class="icon_base" icon="download"/>
        шаблон списки
      </mcw-button>
    </a>
    <a
      href="https://medsafe.pro/shablon_employee_contingent.xlsx"
      class="employees-lists__template-link">
      <mcw-button>
        <mcw-material-icon class="icon_base" icon="download"/>
        шаблон контингенты
      </mcw-button>
    </a>
    <mcw-button v-if="userRank >= 100">
      <mcw-material-icon class="icon_base" icon="upload"/>
      контингенты
      <input
        type="file"
        ref="file"
        accept=".xls, .xlsx"
        class="file-upload__input"
        v-on:change="handleFileUpload(2)"/>
    </mcw-button>
    <mcw-button v-if="userRank >= 100">
      <mcw-material-icon class="icon_base" icon="upload"/>
      поименный список
      <input
        type="file"
        ref="file"
        accept=".xls, .xlsx"
        class="file-upload__input"
        v-on:change="handleFileUpload(1)"/>
    </mcw-button>
    <mcw-data-table>
      <table class="mdc-data-table__table">
        <thead v-show="localItems.length">
          <tr class="mdc-data-table__header-row">
            <th>
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('list_name')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('date_upload')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('n_empl')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('n_calc')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('status_name')}}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody class="mdc-data-table__content">
          <tr class="mdc-data-table__row" v-if="!localItems.length">
            <td></td>
            <td class="mdc-data-table__cell" colspan="7">Нет загруженных документов</td>
          </tr>
          <tr
            class="mdc-data-table__row"
            v-for="(item, index) in localItems"
            :key="index"
            :value="item"
            @click="showStaff(item)">
            <td class="mdc-data-table__cell">
              <mcw-icon-button @click.stop="calculatePrice(item.id_list, item.id_contract)">
                <mcw-material-icon class="icon_base" icon="calculate"/>
              </mcw-icon-button>
            </td>
            <td class="mdc-data-table__cell">{{item.list_name}}</td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{item.date_upload}}</td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{item.n_empl}}</td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{item.n_calc}}</td>
            <td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{item.status_name}}</td>
            <td class="mdc-data-table__cell">
              <mcw-button
                @click.stop="employeeToTree(item)"
              >
                В дерево
              </mcw-button>
              <mcw-button
                v-if="item.id_type === 1 && item.id_status === 1 && userRank >= 100"
                @click.stop="sendToWaiting(item)"
              >
                Отправить в ожидание
              </mcw-button>
            </td>
          </tr>
        </tbody>
      </table>
    </mcw-data-table>
  </div>
</template>

<script>
import { transcriptColumnTitle } from '@/helpers/transcriptor';
import { createJsonFromExcel } from '@/helpers/createJsonFromExcel';

export default {
  name: 'EmployeesLists',
  props: {
    items: Array,
  },
  emits: [
    'showStaff',
    'close',
    'uploadList',
  ],
  data() {
    return {
      localItems: [],
      json: null,
      param: null,
    };
  },
  computed: {
    userRank() {
      return this.$store.getters['user/getUserRank'];
    },
    currentContractId() {
      return this.$store.getters['contractsData/getCurrentContract'] || 0;
    },
  },
  created() {
    this.localItems = this.items.slice();
  },
  methods: {
    showStaff(param) {
      this.$emit('showStaff', param);
    },
    async calculatePrice(idList, idContract) {
      const result = await this.$store.dispatch('employeesData/priceCalculation', { idList, idContract });
      if (result) {
        this.localItems.find((item) => item.id_list === idList).n_calc += 1;
        this.emitter.emit('message', 'Калькуляция рассчитана');
        this.$store.dispatch('employeesData/getDocList');
      } else {
        this.emitter.emit('message', 'Не удалось рассчитать калькуляцию');
      }
    },
    transcriptColumnTitle(column) {
      return transcriptColumnTitle(column);
    },
    async handleFileUpload(param) {
      this.json = await createJsonFromExcel(this.$refs.file.files[0]);
      this.json.shift(); // шапка вырезается по просьбе Горшкова
      this.param = param;
      this.$emit('uploadList', { json: this.json, param: this.param });
    },
    close() {
      this.$emit('close');
    },
    async employeeToTree(item) {
      const params = {
        id_contract: item.id_contract,
        json_param: JSON.stringify({
          id_list: item.id_list,
          id_parent: '',
          override: 1,
        }),
      };
      const result = await this.$store.dispatch('employeesData/employeeToTree', params);
      if (result) {
        this.close();
      } else {
        this.emitter.emit('message', 'Не удалось создать дерево');
      }
    },
    async sendToWaiting(item) {
      const params = {
        id_contract: item.id_contract,
        json_param: JSON.stringify({ id_list: item.id_list }),
      };
      const result = await this.$store.dispatch('employeesData/checkEmployeeToPatient', params);
      if (!result) {
        this.emitter.emit('message', 'Не удалось отправить список');
      }
    },
  },
};
</script>

<style scoped>
.employees-lists{
  padding: 10px;
}
.employees-lists__template-link{
  text-decoration: none;
}
.mdc-data-table__row{
  cursor: pointer;
}
</style>
