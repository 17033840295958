<template>
  <AppHeader :userName="userName" />
      <router-view v-if="isAuth" v-slot="{ Component }">
        <!-- <transition name="fade"> -->
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
        <!-- </transition> -->
      </router-view>
  <div class="auth" v-if="!isAuth && !isLoading">
    <p class="auth__message">Вы не авторизованы в системе</p>
    <a @click.prevent="openLoginPopup" class="auth__link">Нажмите, чтобы открыть форму входа</a>
  </div>
  <mcw-fab
    mini
    icon="bug_report"
    class="bug-report-btn"
    v-if="isAuth"
    @click="openBugReportDialog">
  </mcw-fab>
  <Spinner v-show="isLoading"/>
  <AppFooter/>
  <mcw-dialog
    v-model="isAddOrgOpen">
    <OrgEditor
      v-if="isAddOrgOpen"
      :inn="inn"
      @close="isAddOrgOpen = false" />
  </mcw-dialog>
  <mcw-dialog
    v-model="isEditContactOpen">
    <ContactEditor
      :incoming="contact"
      @close="isEditContactOpen = false"
      v-if="isEditContactOpen"/>
  </mcw-dialog>
  <mcw-dialog
    v-model="isReportDialogOpen">
    <BugReport
      :el="canvasEl"
      v-if="isReportDialogOpen"
      @close="closeBugReport()"/>
  </mcw-dialog>
      <mcw-snackbar
      v-model="isMessage"
      :message="message"
      :dismissAction="false"
    ></mcw-snackbar>
  <mcw-dialog v-model="isExportDialogOpen">
    <ExportDialog
      v-if="isExportDialogOpen && exportData.items.length"
      @close="isExportDialogOpen = false"
      :table="exportData.items"
      :title="exportData.title"
      :showCheckbox="exportData.showCheckbox"/>
  </mcw-dialog>
</template>

<script>
import html2canvas from 'html2canvas';
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';
// import AddOrg from './components/AddOrg.vue';
import OrgEditor from './components/OrgEditor.vue';
import ContactEditor from './components/ContactEditor.vue';
import ExportDialog from './components/ExportDialog.vue';
import Spinner from './components/Spinner.vue';
import BugReport from './components/BugReport.vue';
import { getCookie } from './helpers/cookies';
import { networkErrorsNotifier } from './helpers/networkErrorsNotifier';

let AUTH_HOST = window.location.origin;
if (AUTH_HOST.indexOf('localhost') !== -1) {
  AUTH_HOST = 'http://localhost:8081';
}

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    OrgEditor,
    ContactEditor,
    ExportDialog,
    BugReport,
    Spinner,
  },
  data() {
    return {
      userName: null,
      mode: '',
      isLoading: false,
      isMessage: false,
      message: '',
      isAddOrgOpen: false,
      isEditContactOpen: false,
      contact: {},
      isReportDialogOpen: false,
      canvasEl: null,
      isExportDialogOpen: false,
      exportData: {},
      inn: null,
    };
  },
  async created() {
    this.emitter.on('message', this.showMessage);
    this.emitter.on('addOrg', this.openAddOrg);
    this.emitter.on('editContact', this.openEditContact);
    this.emitter.on('globalLoading', this.setLoader);
    this.$store.dispatch('user/getImei');
    this.authentificate();
    this.$store.subscribeAction({
      before: (action) => {
        if (action.type === 'treeData/loadTree') {
          this.$store.commit('employeesData/RESET_EMPLOYEES_TABLE');
          this.$store.commit('employeesData/RESET_PATIENTS_TABLE');
        }
      },
    });
  },
  computed: {
    isAuth: {
      get() {
        return this.$store.state.user.isAuth;
      },
      set(val) {
        this.$store.commit('user/SET_AUTH', val);
      },
    },
    // isPatientsLoaded() {
    //   return this.$store.state.employeesData.isPatientsLoaded;
    // },
    selectedCompany: {
      get() {
        if (!this.$store.state.user.selectedCompany) {
          return {};
        }
        return this.$store.state.user.selectedCompany;
      },
      set(val) {
        this.$store.commit('user/SET_SELECTED_COMPANY', val);
      },
    },
    companiesList() {
      return this.$store.getters['user/companiesList'];
    },
  },
  watch: {
    isExportDialogOpen(val) {
      if (!val) {
        this.exportData = {};
      }
    },
  },
  mounted() {
    networkErrorsNotifier.setup(this.showMessage, 'Ошибка сетевого соединения');
    const deviceWidth = window.innerWidth;
    this.$store.commit('SET_DEVICE_WIDTH', deviceWidth);
    this.emitter.on('exportToExcel', this.exportToExcel);
    this.emitter.on('selectNewOrg', this.setCompany);
  },
  methods: {
    setLoader(val) {
      this.isLoading = val;
    },
    async authentificate() {
      const storedUserData = this.checkAuth();
      if (storedUserData) {
        try {
          this.isLoading = true;
          const login = await this.$store.dispatch('user/getUserData', storedUserData);
          if (login) {
            const promises = [
              this.$store.dispatch('getDescriptions'),
              this.$store.dispatch('orgsData/getContractTypes'),
              this.$store.dispatch('orgsData/getDoers'),
              this.$store.dispatch('employeesData/getForeignDocsTypes'),
              this.$store.dispatch('employeesData/getTypesForGenerate'),
            ];
            await Promise.all(promises);
            const availableCompanies = await this.$store.dispatch('user/getCompaniesList');
            let selectedCompanyId;
            if (availableCompanies.length === 1) {
              selectedCompanyId = availableCompanies[0].id_org;
            } else {
              selectedCompanyId = localStorage.getItem(`medSafeCompany-${login.id_login}`);
            }
            if (selectedCompanyId) {
              const selectedCompany = availableCompanies
                .find((company) => company.id_org === Number(selectedCompanyId));
              if (selectedCompany) {
                this.$store.dispatch('user/setSelectedCompany', selectedCompany);
              }
            }
          }
        } catch (err) {
          console.error(err);
        } finally {
          this.isLoading = false;
        }
      } else {
        this.isAuth = false;
      }
    },
    exportToExcel(data) {
      this.exportData = data;
      this.isExportDialogOpen = true;
    },
    closeBugReport() {
      this.canvasEl = null;
      this.isReportDialogOpen = false;
    },
    openBugReportDialog() {
      this.emitter.emit('globalLoading', true);
      html2canvas(document.body).then((canvas) => {
        this.canvasEl = canvas;
        this.isReportDialogOpen = true;
      });
    },
    openAddOrg(inn = null) {
      if (inn) {
        this.inn = inn;
      } else {
        this.inn = null;
      }
      this.isAddOrgOpen = true;
    },
    openEditContact(item) {
      this.contact = item;
      this.isEditContactOpen = true;
    },
    showMessage(message) {
      this.message = message;
      this.isMessage = true;
    },
    checkAuth() {
      const storedUserData = {
        login: getCookie('id_login'),
        token: getCookie('TK'),
      };
      if (storedUserData.login) {
        return storedUserData;
      }
      return false;
    },
    openLoginPopup() {
      const receiveMessage = (event) => {
        if (event.data === 'login') {
          this.authentificate();
          window.location.reload();
        }
      };
      const windowFeatures = 'popup, scrollbars=yes, resizable=yes, toolbar=1 menubar=yes';
      const authWindow = window.open(`${AUTH_HOST}/login`, 'Auth', windowFeatures);
      if (authWindow) {
        window.addEventListener('message', receiveMessage);
      }
    },
    setCompany(payload) {
      this.selectedCompany = payload;
      this.selectCompany();
    },
    async selectCompany() {
      try {
        this.isLoading = true;
        if (this.selectedCompany.temp) {
          await this.$store.dispatch('user/getCompaniesList', this.selectedCompany.id_org);
          // if (this.$store.state.orgsData.fullOrgsList.length) {
          //   await this.$store.dispatch('orgsData/getFullOrgsList');
          // }
        }
        this.$store.commit('employeesData/SET_ALL_PATIENTS', []);
        await this.$store.dispatch('user/setSelectedCompany', this.selectedCompany);
        this.$store.commit('employeesData/IS_PATIENTS_LOADED', false);
        this.emitter.emit('showParticularTable');
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@use "styles/theme";
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s ease;
// }

// .fade-enter-from,
// .fade-leave-to {
//   opacity: 0;
// }
.tooltip{
  position: absolute;
  padding: 5px;
  background: rgba(0, 0, 0, 0.8);
  color: #FFF;
  z-index: 100;
  border-radius: 8px;
}
</style>
